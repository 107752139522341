// @flow strict
import React from 'react';
import Img from 'gatsby-image';
import styles from './Content.module.scss';
import type { ChildImageSharp } from '../../../types';

type Props = {
  body: string,
  title: string,
  socialImage: ChildImageSharp,
};

const Content = ({ body, title, socialImage }: Props) => (
  <div className={styles['content']}>
    <h1 className={styles['content__title']}>{title}</h1>
    {socialImage &&  <Img className={styles['content__image']} fluid={socialImage.childImageSharp.fluid}/>}
    <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }}/>
  </div>
);

export default Content;
